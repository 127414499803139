var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "editor", attrs: { tabindex: "-1" } },
    [
      _c("div", { staticClass: "form-row align-items-center" }, [
        _c(
          "div",
          { staticClass: "col-md-3 mb-2 mb-md-0" },
          [
            _c(
              "collapse-item",
              { staticClass: "text-default", attrs: { id: _vm.id } },
              [_vm._v(_vm._s(_vm.label))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-9 d-md-flex justify-content-end" }, [
          _c("div", { staticClass: "btn-group btn-group-sm mr-1 mr-md-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: {
                  type: "button",
                  tabindex: "-1",
                  title: `${_vm.$t("Undo")} (${_vm.$t("Ctrl")}+Z)`,
                  disabled: _vm.treeView || !_vm.undoable,
                },
                on: { click: _vm.undo },
              },
              [_c("i", { staticClass: "fa-solid fa-rotate-left" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: {
                  type: "button",
                  tabindex: "-1",
                  title: `${_vm.$t("Redo")} (${_vm.$t("Ctrl")}+Y)`,
                  disabled: _vm.treeView || !_vm.redoable,
                },
                on: { click: _vm.redo },
              },
              [_c("i", { staticClass: "fa-solid fa-rotate-right" })]
            ),
            _vm._v(" "),
            _vm.editingMode_
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: {
                      type: "button",
                      tabindex: "-1",
                      title: _vm.$t("Paste extras"),
                      disabled: _vm.treeView,
                    },
                    on: { click: _vm.showPasteDialog },
                  },
                  [_c("i", { staticClass: "fa-solid fa-paste" })]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editingMode_
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: {
                      type: "button",
                      tabindex: "-1",
                      title: _vm.$t("Reset editor"),
                      disabled: _vm.treeView,
                    },
                    on: { click: _vm.resetEditor },
                  },
                  [_c("i", { staticClass: "fa-solid fa-rotate" })]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: {
                  type: "button",
                  tabindex: "-1",
                  title: `${_vm.$t("Toggle mode")} (${_vm.$t("Ctrl")}+M)`,
                  disabled: _vm.treeView,
                },
                on: {
                  click: function ($event) {
                    _vm.editingMode_ = !_vm.editingMode_
                  },
                },
              },
              [
                !_vm.editingMode_
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("Editing mode")))])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("Simple mode")))]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-light",
              attrs: {
                type: "button",
                tabindex: "-1",
                title: `${_vm.$t("Toggle view")} (${_vm.$t("Ctrl")}+E)`,
              },
              on: {
                click: function ($event) {
                  _vm.treeView = !_vm.treeView
                },
              },
            },
            [
              _vm.treeView
                ? _c("span", [
                    _c("i", { staticClass: "fa-solid fa-pencil" }),
                    _vm._v(" " + _vm._s(_vm.$t("Editor view")) + "\n        "),
                  ])
                : _c("span", [
                    _c("i", { staticClass: "fa-solid fa-bars-staggered" }),
                    _vm._v(" " + _vm._s(_vm.$t("Tree view")) + "\n        "),
                  ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-2", attrs: { id: _vm.id } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.treeView,
                expression: "!treeView",
              },
            ],
            staticClass: "pt-1",
          },
          [
            _c(
              "extras-editor-items",
              {
                ref: "extras",
                attrs: {
                  extras: _vm.extras,
                  "template-endpoint": _vm.templateEndpoint,
                  "enable-term-search": Boolean(_vm.termsEndpoint),
                  "editing-mode": _vm.editingMode_,
                },
                on: {
                  "show-term-search": function ($event) {
                    return _vm.showTermSearch($event)
                  },
                  "save-checkpoint": _vm.saveCheckpoint,
                },
              },
              [
                _vm.templateEndpoint
                  ? _c(
                      "div",
                      { staticClass: "form-row align-items-center mt-2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "offset-md-5 col-md-7 offset-xl-7 col-xl-5",
                          },
                          [
                            _c("dynamic-selection", {
                              attrs: {
                                "container-classes": "select2-single-sm",
                                placeholder: _vm.$t("Select a template"),
                                endpoint: _vm.templateEndpoint,
                                "reset-on-select": true,
                              },
                              on: { select: _vm.selectTemplate },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.treeView,
                expression: "treeView",
              },
            ],
            staticClass: "pt-1",
          },
          [
            _c("div", { staticClass: "card text-break overflow-auto" }, [
              _c(
                "div",
                { staticClass: "card-body py-1" },
                [
                  _c("extras-editor-tree-view", {
                    attrs: { extras: _vm.extras },
                    on: { "focus-extra": _vm.focusExtra },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.serializedExtras },
      }),
      _vm._v(" "),
      _vm.termsEndpoint
        ? _c("term-search", {
            ref: "termSearch",
            attrs: { endpoint: _vm.termsEndpoint },
            on: { "select-term": _vm.selectTerm },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "pasteDialog",
          staticClass: "modal",
          attrs: { tabindex: "-1" },
          on: {
            change: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog modal-dialog-centered modal-xl" },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("strong", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.$t("Add extras as JSON"))),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.pastedExtras,
                        expression: "pastedExtras",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control form-control-sm paste-area-body",
                    class: { "has-error": _vm.pastedExtrasError },
                    attrs: { spellcheck: "false", rows: "20" },
                    domProps: { value: _vm.pastedExtras },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.pastedExtras = $event.target.value.trim()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card bg-light paste-area-footer" },
                    [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "Note that only the metadata format used by Kadi4Mat is currently supported."
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.pastedExtrasError
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.pastedExtrasError)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-primary",
                          attrs: {
                            type: "button",
                            disabled: !_vm.pastedExtras,
                          },
                          on: { click: _vm.pasteExtras },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("Add extras")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 d-flex justify-content-end" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-light",
                            attrs: {
                              type: "button",
                              disabled: !_vm.pastedExtras,
                            },
                            on: { click: _vm.formatExtras },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("Format JSON")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: { type: "button", "data-dismiss": "modal" },
      },
      [_c("i", { staticClass: "fa-solid fa-xmark fa-xs" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }